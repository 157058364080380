import { afterNextRender, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {
  private _accepted: boolean | null = true;

  constructor() {
    afterNextRender(() => {
        const cookieAcceptString = this.getCookieValue('bn_cookie_consent');
        this._accepted = cookieAcceptString === null ? null : cookieAcceptString === 'true';
      }
    );
  }

  public get accepted(): boolean | null {
    return this._accepted;
  }

  public set accepted(value: boolean) {
    this.setAcceptedToStorage(value);
  }

  private setAcceptedToStorage(value: boolean): void {
    const stringVal = value ? 'true' : 'false';
    const domain = window.location.hostname;
    document.cookie = `bn_cookie_consent=${stringVal};path=/;domain=` + domain;
    this._accepted = value;
  }

  private getCookieValue(cookieName: string): string | null {
    const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
    const cookie = cookies.find((cookie) => cookie.startsWith(cookieName));
    return cookie ? cookie.split('=')[1] : null;
  }
}
