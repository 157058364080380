import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CookieConsentComponent } from './ui/cookie-consent/cookie-consent.component';
import { CookieConsentService } from './ui/cookie-consent.service';
import { GtmEventService } from './ui/gtm-event.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    CookieConsentComponent
  ],
  providers: [
    CookieConsentService,
    GtmEventService
  ],
  template: `
    @if (consentService.accepted === null) {
      <bn-cookie-consent></bn-cookie-consent>
    }
    <router-outlet/>
  `
})
export class AppComponent {
  consentService: CookieConsentService = inject(CookieConsentService);
  gtmEventService: GtmEventService = inject(GtmEventService);
}
