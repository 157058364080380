import { Routes } from '@angular/router';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'blinking-noodles'
  },
  {
    path: 'blinking-noodles',
    component: MainLayoutComponent,
    loadChildren: () => import('./feature/blinking-noodles/routes')
  },
  {
    path: 'about-us',
    component: MainLayoutComponent,
    loadChildren: () => import('./feature/about-us/routes')
  },
  {
    path: 'authenticity-check',
    component: MainLayoutComponent,
    loadChildren: () => import('./feature/authenticity-check/routes')
  },
  {
    path: 'my-blinking-noodle',
    component: MainLayoutComponent,
    loadChildren: () => import('./feature/personalize/routes')
  },
  {
    path: 'workshop',
    component: MainLayoutComponent,
    loadChildren: () => import('./feature/workshop/routes')
  },
  {
    path: 'webshop',
    component: MainLayoutComponent,
    loadChildren: () => import('./feature/webshop/routes')
  },
  {
    component: MainLayoutComponent,
    path: 'contact',
    loadChildren: () => import('./feature/contact/routes')
  },
  {
    component: MainLayoutComponent,
    path: 'community',
    loadChildren: () => import('./feature/community/routes')
  },
  {
    component: MainLayoutComponent,
    path: 'statements',
    loadChildren: () => import('./feature/legal/routes')
  },
  {
    component: MainLayoutComponent,
    path: 'eloregisztracio',
    loadChildren: () => import('./feature/campaign/routes')
  },
  {
    component: MainLayoutComponent,
    path: '**',
    loadChildren: () => import('./ui/status404/not-found-route')
  }
];
