import { Component, inject, ViewEncapsulation } from '@angular/core';
import { CookieConsentService } from '../cookie-consent.service';

@Component({
  selector: 'bn-cookie-consent',
  standalone: true,
  imports: [],
  template: `
    <div class="backdrop" [class.hidden]="accepted !== null" (scroll)="disableScroll($event)">
      <div class="content">
        <h1>Máár megint azok a fránya sütik...</h1>
        <p>
          A weboldalunkon mi is sütiket használunk, hogy <a (click)="showFullDescription()">elemezni tudjuk mi történik
          az oldalon</a> amikor használjátok.
          Így amikor valamit módosítunk az oldalon, akkor tudjunk javítani a használhatóságon. Az "Elfogadom"
          gombra kattintva beleegyezel ezeknek az információknak a rögzítésébe.
        </p>
        <div class="full-description" [class.hidden]="!displayFullDescription">
          <h2>Miért használunk sütiket?</h2>
          <p>
            A sütik olyan kis szöveges fájlok, amelyeket a weboldalak a böngésződ tárolnak, hogy azokat később vissza
            tudják olvasni. A sütik segítségével a weboldalak megjegyzik a beállításaidat, így például ha bejelentkeztél
            egy weboldalra, akkor a sütik segítségével a következő alkalommal nem kell újra bejelentkezned.
          </p>
          <h2>Milyen sütiket használunk?</h2>
          <p>
            A weboldalunkon két fajta sütiket használunk: az alapvető sütiket és a teljesítmény sütiket.
          </p>
          <h3>Alapvető sütik</h3>
          <p>
            Ezek a sütik elengedhetetlenek ahhoz, hogy a weboldalunk működjön. Ezek a sütik nem gyűjtenek információt,
            amely azonosítana téged.
          </p>
          <h3>Teljesítmény sütik</h3>
          <p>
            Ezek a sütik segítenek nekünk megérteni, hogy hogyan használjátok a weboldalunkat, és segítenek nekünk
            javítani a weboldalunkat. Ezek a sütik információkat gyűjtenek arról, hogy mely oldalakat nézitek meg, és
            melyik oldalakon töltötök a legtöbb időt.
          </p>
        </div>
        <div class="buttons">
          <button class="button primary" (click)="acceptDefault()">Elfogadom</button>
          <button class="button" [class.hidden]="!displayFullDescription" (click)="acceptEssentialsOnly()">Csak a
            feltétlen szükségeseket fogadom el
          </button>

          <button class="button" [class.hidden]="displayFullDescription" (click)="showFullDescription()">További
            lehetőségek
          </button>

        </div>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  styles: `
    body {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
    }

    bn-cookie-consent {
      .backdrop {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
      }

      .content {
        margin: 0.5em;
        position: fixed;
        box-sizing: border-box;
        z-index: 1001;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 2rem;
        border-radius: 1rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
        max-height: 100%;
        width: 80%;
        overflow: auto;
      }

      // 400px alatt paddingot csökkentjük
      @media (max-width: 400px) {
        .content {
          padding: 1rem;
          margin: 0.1em;
        }
      }
      @media (max-width: 1400px) {
        .content {
          width: 95%;
        }
      }

      .hidden {
        display: none;
      }

      .buttons {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
      }
    }
  `
})
export class CookieConsentComponent {
  service = inject(CookieConsentService);
  /**
   * True: full
   * false: essential
   */
  public accepted: boolean | null = this.service.accepted;
  public displayFullDescription = false;

  public acceptDefault() {
    this.service.accepted = true;
  }

  public acceptEssentialsOnly() {
    this.service.accepted = false;
  }

  public showFullDescription() {
    this.displayFullDescription = true;
  }

  public disableScroll(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
