import { Component, inject } from '@angular/core';
import { JsonPipe } from '@angular/common';
import { BnOrderService } from '../bn-order.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'bn-cart',
  standalone: true,
  imports: [
    JsonPipe
  ],
  template: `
    <div class="basket" (click)="toBasket()">
      @if (cartItems().length > 0) {
        <span class="count">{{ cartItems().length }}</span>
      }
      <img [class.loading]="loading()" src="/assets/decoration/basket.svg" alt="Kosár">
    </div>
  `,
  styles: `
    .count {
      position: relative;
      top: -22px;
      right: -50px;
      background-color: #ff0000;
      color: white;
      border-radius: 50%;
      padding: 5px;
      font-size: 10px;
    }

    .basket {
      cursor: pointer;
    }
  `
})
export class CartComponent {
  // ez azért van itt betöltve, hogy a service már itt betöltődjön
  private orderService = inject(BnOrderService);
  private store = this.orderService.getCartStore();
  cartItems = this.store.items;
  loading = this.store.loading;
  loadingObservable = toObservable(this.loading);

  toBasket() {
    if (this.cartItems().length === 0 || this.store.tokenValue() === '') {
      return;
    }

    if (!this.loading()) {
      window.location.href = environment.shopUrl + '/hu_HU/cart/?tokenValue=' + this.store.tokenValue();
    }

    // épp tölt, úgyhogy átrakjuk betöltés után:
    this.loadingObservable.pipe(
      filter(loading => !loading),
      take(1)
    ).subscribe(() => {
      window.location.href = environment.shopUrl + '/hu_HU/cart/?tokenValue=' + this.store.tokenValue();
    });
  }
}
