import { Component, inject, isDevMode } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import {
  NewsletterSubscribeFormComponent
} from '../../ui/newsletter-subscribe-form/newsletter-subscribe-form.component';
import { CartComponent } from '../../feature/webshop/cart/cart.component';
import { WebshopDebugComponent } from '../../feature/webshop/webshop-debug/webshop-debug.component';
import { FormsModule } from '@angular/forms';
import { MessageFlashService } from '../../ui/message-flash.service';

@Component({
  selector: 'bn-main-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    NewsletterSubscribeFormComponent,
    CartComponent,
    WebshopDebugComponent,
    FormsModule
  ],
  template: `
    <header>
      <div class="logo">
        @if (isHoliday) {
          <img routerLink="/blinking-noodles" src="/assets/logo/BN_c1_main_holiday.svg" alt="Blinking Noodles Logó">
        } @else {
          <img routerLink="/blinking-noodles" src="/assets/logo/BN_c1_main.svg" alt="Blinking Noodles Logó">
        }
      </div>
      <div class="menu">
        <div class="top">
          <bn-cart></bn-cart>
        </div>

        <nav role="navigation" id="menuToggle">
          <input type="checkbox" [(ngModel)]="menuToggleCheckboxChecked"/>
          <div class="burger" role="button">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <menu id="menu" (click)="clickEvent($event)">
            <li>
              <a routerLink="/blinking-noodles" routerLinkActive="active">Főoldal</a>
            </li>
            <li>
              <a routerLink="/about-us" routerLinkActive="active">Rólunk</a>
            </li>
            <li>
              <a routerLink="/webshop" routerLinkActive="active">Figurák</a>
              <menu class="sub">
                <li><a routerLink="/webshop" routerLinkActive="active">Kutyás kulcstartók</a></li>
                <li><a routerLink="/my-blinking-noodle" routerLinkActive="active">Egyedi figurák</a></li>
                <!--  <li><a routerLink="/authenticity-check" routerLinkActive="active">Eredetiség</a></li> -->
                <li><a routerLink="/workshop" routerLinkActive="active">Workshop</a></li>
              </menu>
            </li>
            <li>
              <a routerLink="/community" routerLinkActive="active">Közösség</a>
            </li>
            <!--            <li>
                          <a routerLink="/blog" routerLinkActive="active">Blog</a>
                        </li>
                        -->
            <li>
              <a routerLink="/contact" routerLinkActive="active">Kapcsolat</a>
            </li>
          </menu>
        </nav>
      </div>
    </header>
    <main>
      @if (messages().length) {
        @for (message of messages(); track message.id) {
          <div class="message"
               [class.info]="message.level === 'info'"
               [class.warning]="message.level === 'warning'"
               [class.error]="message.level === 'error'"
               [class.hidden]="!message.visible"
               [class.visible]="message.visible"
          >
            {{ message.content }}
            <button class="button" (click)="hideMessage(message.id)">Rendben</button>
          </div>
        }
      }

      <router-outlet></router-outlet>
      @if (devEnvironment) {
        <bn-webshop-debug></bn-webshop-debug>
      }
    </main>
    <footer>
      <div class="container">
        <div class="contact">
          Palazzo Reale Kft.<br/>
          Ügyfélszolgálat: <a href="tel:+36 20 261 3277">+36 20 261 3277</a><br/>
          <a href="tel:+36 20 321 8090">+36 20 321 8090</a><br/>
          H-P.: 09.00 - 17:00<br>
          Mail: <a href="mailto:ugyfelszolgalat@blinkingnoodles.hu">ugyfelszolgalat&#64;blinkingnoodles.hu</a>
        </div>
        <div class="legal">
          <a routerLink="/statements/privacy-policy-how-we-handle-your-data">Adatkezelési tájékoztató</a><br/>
          <a routerLink="/statements/general-terms-of-service">Általános szerződési feltételek</a><br/>
          <a routerLink="/statements/shipping-and-returns">Szállítási információk</a><br/>
          <img src="/assets/logo/barion-card-strip-intl.svg" alt="Barion és kártyatársaságok logója">
        </div>
        <div class="newsletter">
          <bn-newsletter-subscribe-form>
            <p>Iratkozz fel hírlevelünkre, hogy időben értesülj az új nudlikról, eseményekről!</p>
          </bn-newsletter-subscribe-form>
        </div>
      </div>
    </footer>
  `
})
export class MainLayoutComponent {
  private messageService = inject(MessageFlashService);
  messages = this.messageService.getMessages();
  devEnvironment = isDevMode();

  menuToggleCheckboxChecked = false;

  isHoliday = false;

  constructor() {
    this.isHoliday = new Date().getMonth() === 11;
  }

  clickEvent(click: any) {
    // zárjuk, ha a-ra klikkelt, mert azt hiszi elromlott, mert nem tűnik el a menü
    if (click.target.tagName === 'A') {
      this.menuToggleCheckboxChecked = false;
    }
  }

  hideMessage(id: number) {
    this.messageService.hide(id);
  }
}
