import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  importProvidersFrom,
  LOCALE_ID, provideExperimentalZonelessChangeDetection
} from '@angular/core';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { ApiModule } from './feature/openapi/api.module';
import localeHu from '@angular/common/locales/hu';
import { registerLocaleData } from '@angular/common';
import { DEFAULT_CURRENCY_DIGITS_INFO, DEFAULT_CURRENCY_DISPLAY } from './ui/currency-defaults.pipe';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { environment } from '../environments/environment';

registerLocaleData(localeHu);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes,
      withComponentInputBinding(),
      withInMemoryScrolling(
        {
          scrollPositionRestoration: "enabled",
          anchorScrolling: "enabled"
        }
      )),
    provideClientHydration(withEventReplay()),
    provideExperimentalZonelessChangeDetection(),
    provideHttpClient(withFetch()),
    importProvidersFrom(
      ApiModule.forRoot({
        rootUrl: environment.shopUrl
      })
    ),
    {provide: LOCALE_ID, useValue: 'hu-HU'},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'HUF'},
    {provide: DEFAULT_CURRENCY_DISPLAY, useValue: 'symbol'},
    {provide: DEFAULT_CURRENCY_DIGITS_INFO, useValue: '1.0-0'},
    provideEnvironmentNgxMask()
  ]
};
