/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AddressService } from './services/address.service';
import { AdjustmentService } from './services/adjustment.service';
import { AdministratorService } from './services/administrator.service';
import { SecurityService } from './services/security.service';
import { AvatarImageService } from './services/avatar-image.service';
import { CatalogPromotionTranslationService } from './services/catalog-promotion-translation.service';
import { CatalogPromotionService } from './services/catalog-promotion.service';
import { ChannelPriceHistoryConfigService } from './services/channel-price-history-config.service';
import { ChannelPricingLogEntryService } from './services/channel-pricing-log-entry.service';
import { ChannelService } from './services/channel.service';
import { ShopBillingDataService } from './services/shop-billing-data.service';
import { CountryService } from './services/country.service';
import { ProvinceService } from './services/province.service';
import { CurrencyService } from './services/currency.service';
import { CustomerGroupService } from './services/customer-group.service';
import { CustomerService } from './services/customer.service';
import { ExchangeRateService } from './services/exchange-rate.service';
import { GatewayConfigService } from './services/gateway-config.service';
import { LocaleService } from './services/locale.service';
import { OrderItemUnitService } from './services/order-item-unit.service';
import { OrderItemService } from './services/order-item.service';
import { OrderService } from './services/order.service';
import { PaymentService } from './services/payment.service';
import { ShipmentService } from './services/shipment.service';
import { PaymentMethodTranslationService } from './services/payment-method-translation.service';
import { PaymentMethodService } from './services/payment-method.service';
import { ProductAssociationTypeTranslationService } from './services/product-association-type-translation.service';
import { ProductAssociationTypeService } from './services/product-association-type.service';
import { ProductAssociationService } from './services/product-association.service';
import { ProductAttributeTranslationService } from './services/product-attribute-translation.service';
import { ProductAttributeValueService } from './services/product-attribute-value.service';
import { ProductAttributeService } from './services/product-attribute.service';
import { ProductImageService } from './services/product-image.service';
import { ProductOptionTranslationService } from './services/product-option-translation.service';
import { ProductOptionValueService } from './services/product-option-value.service';
import { ProductOptionService } from './services/product-option.service';
import { ProductReviewService } from './services/product-review.service';
import { ProductTaxonService } from './services/product-taxon.service';
import { ProductTranslationService } from './services/product-translation.service';
import { ProductVariantTranslationService } from './services/product-variant-translation.service';
import { ProductVariantService } from './services/product-variant.service';
import { ProductService } from './services/product.service';
import { PromotionCouponService } from './services/promotion-coupon.service';
import { PromotionTranslationService } from './services/promotion-translation.service';
import { PromotionService } from './services/promotion.service';
import { ShippingCategoryService } from './services/shipping-category.service';
import { ShippingMethodTranslationService } from './services/shipping-method-translation.service';
import { ShippingMethodService } from './services/shipping-method.service';
import { StatisticsService } from './services/statistics.service';
import { TaxCategoryService } from './services/tax-category.service';
import { TaxRateService } from './services/tax-rate.service';
import { TaxonImageService } from './services/taxon-image.service';
import { TaxonTranslationService } from './services/taxon-translation.service';
import { TaxonService } from './services/taxon.service';
import { ZoneMemberService } from './services/zone-member.service';
import { ZoneService } from './services/zone.service';
import { ContactService } from './services/contact.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AddressService,
    AdjustmentService,
    AdministratorService,
    SecurityService,
    AvatarImageService,
    CatalogPromotionTranslationService,
    CatalogPromotionService,
    ChannelPriceHistoryConfigService,
    ChannelPricingLogEntryService,
    ChannelService,
    ShopBillingDataService,
    CountryService,
    ProvinceService,
    CurrencyService,
    CustomerGroupService,
    CustomerService,
    ExchangeRateService,
    GatewayConfigService,
    LocaleService,
    OrderItemUnitService,
    OrderItemService,
    OrderService,
    PaymentService,
    ShipmentService,
    PaymentMethodTranslationService,
    PaymentMethodService,
    ProductAssociationTypeTranslationService,
    ProductAssociationTypeService,
    ProductAssociationService,
    ProductAttributeTranslationService,
    ProductAttributeValueService,
    ProductAttributeService,
    ProductImageService,
    ProductOptionTranslationService,
    ProductOptionValueService,
    ProductOptionService,
    ProductReviewService,
    ProductTaxonService,
    ProductTranslationService,
    ProductVariantTranslationService,
    ProductVariantService,
    ProductService,
    PromotionCouponService,
    PromotionTranslationService,
    PromotionService,
    ShippingCategoryService,
    ShippingMethodTranslationService,
    ShippingMethodService,
    StatisticsService,
    TaxCategoryService,
    TaxRateService,
    TaxonImageService,
    TaxonTranslationService,
    TaxonService,
    ZoneMemberService,
    ZoneService,
    ContactService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
