import { Component, computed, inject } from '@angular/core';
import { CartStore } from '../state/cart-store';
import { JsonPipe } from '@angular/common';
import { ProductIndexStore, ProductShowStore, ProductVariantStore } from '../state/stores';
import { getState } from '@ngrx/signals';

@Component({
  selector: 'bn-webshop-debug',
  standalone: true,
  imports: [
    JsonPipe
  ],
  template: `
    <button class="accordion" (click)="toggleAccordion(cartStatePanel)">CartState</button>
    <pre class="panel" #cartStatePanel>{{ cartState() | json }}</pre>

    <button class="accordion" (click)="toggleAccordion(productIndexStorePanel)">ProductIndexStore</button>
    <pre class="panel" #productIndexStorePanel>{{ productIndexState() | json }}</pre>

    <button class="accordion" (click)="toggleAccordion(productVariantStorePanel)">ProductVariantStore</button>
    <pre class="panel" #productVariantStorePanel>{{ productVariantState() | json }}</pre>

    <button class="accordion" (click)="toggleAccordion(productShowStorePanel)">ProductShowStore</button>
    <pre class="panel" #productShowStorePanel>{{ productShowState() | json }}</pre>
  `,
  styles: `
    /* Style the buttons that are used to open and close the accordion panel */
    .accordion {
      background-color: #eee;
      color: #444;
      cursor: pointer;
      padding: 18px;
      width: 100%;
      text-align: left;
      border: none;
      outline: none;
      transition: 0.4s;
    }

    /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
    .active, .accordion:hover {
      background-color: #ccc;
    }

    /* Style the accordion panel. Note: hidden by default */
    .panel {
      padding: 0 18px;
      background-color: white;
      display: none;
      overflow: hidden;
      &.active {
        display: block;
      }
    }
  `
})
export class WebshopDebugComponent {
  private cartStore = inject(CartStore);
  public cartState = computed(() => getState(this.cartStore));

  private productIndexStore = inject(ProductIndexStore);
  public productIndexState = computed(() => getState(this.productIndexStore));

  private productVariantStore = inject(ProductVariantStore);
  public productVariantState = computed(() => getState(this.productVariantStore));

  public productShowStore = inject(ProductShowStore);
  public productShowState = computed(() => getState(this.productShowStore));

  toggleAccordion(element: HTMLElement) {
    element.classList.toggle('active');
  }
}
