import { afterNextRender, inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare global {
  interface Window {
    dataLayer: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GtmEventService {
  private router = inject(Router);
  private navigationSubscription = this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      this.logPageView(event.url);
    }
  });

  /**
   * SSR-nek kell valalmi, hogy tudja piszkálni, de ezt első render után cseréljük a rendes window-ra.
   * @private
   */
  private window: Window | null = null;

  constructor() {
    afterNextRender(() => {
      if (window) {
        // @ts-ignore
        if (!window.dataLayer) {
          window.dataLayer = window.dataLayer || [];
        }

        this.window = window;
      }
    });
  }

  private send(obj: {}) {
    if (this.window && obj)
      this.window.dataLayer.push(obj);
  }

  logPageView(url: string) {
    const hit = {
      event: 'content-view',
      pageName: url
    };
    this.send(hit);
  }

  logEvent(event: string, category: string, action: string, label: string) {
    const hit = {
      event: event,
      category: category,
      action: action,
      label: label
    };
    this.send(hit);
  }

}
